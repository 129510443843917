/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Main from '../page-components/legal/Terms';

const Terms = ({ location: { pathname } }) => {
  //

  return (
    <article>
      <SEO title="Terms of Use" description="Terms of Use" />
      <Main />
    </article>
  );
};

export default Terms;

Terms.propTypes = {
  location: PropTypes.object.isRequired,
};
